import React from 'react'
import { ControlSelect } from '../../molecules/FormItem'
import { data } from '../../../static/db.json'

export const Timezone = () => (
  <ControlSelect
    label="Выберите свой часовой пояс"
    name="timezone"
    options={data.timezone.map(({ shortcut, desc }) => ({
      text: desc,
      value: shortcut
    }))}
    virtual={false}
  />
)
