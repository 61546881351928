import React, { useState } from 'react'
import styled from 'styled-components'
import { Text } from '../../atoms/Text'
import { documents } from '../../../static/db.json'
import { experiment } from './Experiment'
import { size } from '../../constants'

export const Agreement = styled(({ className }) => {
  const [show] = useState(() => experiment('AgreeListCheckboxes', 'hide'))
  return (
    show && (
      <div className={className}>
        <Text.Average semiBold>
          Отправляя заявку, я соглашаюсь на{' '}
          <a href={documents.urlPolicy} rel="noreferrer" target="_blank">
            обработку персональных данных
          </a>{' '}
          и с{' '}
          <a href={documents.urlAgreement} rel="noreferrer" target="_blank">
            условиями пользовательского соглашения
          </a>
        </Text.Average>
      </div>
    )
  )
})`
  padding-right: 46px;
  margin-top: -8px;
  color: #9b9b9b;

  @media (max-width: ${size.sm}) {
    padding-right: 0;
  }

  & a {
    color: #9b9b9b;
    text-decoration: underline;
  }
`
