import React from 'react'
import { CodeStepForm } from './form'
import { RegistrationRoute } from '../../../../components/amplitude/auth/vars'
import { useTrackCodeEnterEffect } from '../../../../components/amplitude/auth/trackCodeEnter'
// import { useTrackRegistraionPasswordCreateEffect } from '../../../components/amplitude/trackRegistraionPasswordCreate'

export const Code = ({ onCodeSubmit }) => {
  useTrackCodeEnterEffect({ route: RegistrationRoute.Application })

  return <CodeStepForm onSubmit={onCodeSubmit} />
}
