import React from 'react'
import { ControlTextArea } from '../../../youtalk-storybook/src/ui'
import { getInitialQueryParams } from '../../atoms/initialQueryParams'
import { notEmpty } from '../../atoms/FormItems/validators/notEmpty'

export const useFieldRequiredProps = () => {
  const params = getInitialQueryParams([
    'utm_source',
    'utm_medium',
    'utm_campaign'
  ])

  if (!params) return {}

  const {
    utm_source: utmSource,
    utm_medium: utmMedium,
    utm_campaign: utmCampaign
  } = params

  if (
    utmSource === 'yandex' &&
    utmMedium === 'cpc' &&
    !utmCampaign.includes('brand')
  ) {
    return {
      required: true,
      validate: notEmpty(
        'Пожалуйста, укажите запрос, с которым нужна помощь психолога'
      )
    }
  }
  return {}
}

export const AboutHelp = () => {
  const requiredProps = useFieldRequiredProps()
  return (
    <ControlTextArea
      countCharacters={{ visible: true, max: 2200 }}
      id="aboutHelp"
      label="С чем необходима помощь психолога?"
      name="aboutHelp"
      placeholder="Опишите проблему своими словами..."
      {...requiredProps}
    />
  )
}
