/* eslint-disable max-lines-per-function */
import { Event as GAEvent } from '../../../../components/GA'
import { REGISTRATION_WEBSITE_RESULT, RegistrationErrors } from '../../vars'
import { amplitudeIdentifyRegistrationDate } from '../../../../components/amplitude/identify'
import { authCarrot } from '../../../../api'
import { trackCodeWrong } from '../../../../components/amplitude/auth/trackCodeWrong'
import { useConfirmRegistrationMutation } from '../../query'
import { useErrorModalContext } from '../../hooks/useErrorProvider'
import { useSendCodeModalContext } from './useSendCodeModalContext'
import { useSessionStorage } from '../../../../../youtalk-storybook/src/ui'

export const useCodeSubmit = () => {
  const [secondStepRegisterMutation] = useConfirmRegistrationMutation()
  const context = useSendCodeModalContext()
  const errorContext = useErrorModalContext()
  const [_, setRegistrResult] = useSessionStorage(
    REGISTRATION_WEBSITE_RESULT,
    {}
  )
  return async (values) => {
    try {
      const response = await secondStepRegisterMutation({
        variables: {
          input: {
            code: values.code,
            token: context.formData.token
          }
        }
      })
      if (
        response.data.confirmRegistration.code ===
        RegistrationErrors.USER_EXISTS
      ) {
        errorContext.onIdentityTakenError()
        return
      }
      if (
        response.data.confirmRegistration.code ===
        RegistrationErrors.INCORRECT_CODE
      ) {
        trackCodeWrong()
        return { code: response.data.confirmRegistration.message }
      }
      if (
        response.data.confirmRegistration.code ===
        RegistrationErrors.NO_SLOT_AVAILABLE
      ) {
        return { code: response.data.confirmRegistration.message }
      }
      if (response.data.confirmRegistration.jwt) {
        const registrResult = {
          jwt: response.data.confirmRegistration.jwt,
          role: 'client',
          id: response.data.confirmRegistration.id,
          hadAnyRelationships:
            response.data.confirmRegistration.hadAnyRelationships,
          newPsychologistId:
            response.data.confirmRegistration.newPsychologistId,
          afterApplication: true
        }
        setRegistrResult(registrResult)
        context.updateContext({ loading: true })
        amplitudeIdentifyRegistrationDate()
        await authCarrot(response.data.confirmRegistration.id)
        GAEvent.registrationOk('from_registration')
        // eslint-disable-next-line no-restricted-globals
        location.href = process.env.GATSBY_LK_URL
      }
    } catch (err) {
      console.log(err)
      errorContext.onServerError()
    }
  }
}
