import React, { useEffect, useMemo } from 'react'
import { AuthModal } from '../index.styles'
import { Code } from './Code'
import { Event as GAEvent } from '../../../components/GA'
import { Icons } from '../../../../youtalk-storybook/src/ui'
import {
  SendCodeModalModalContextProvider,
  useSendCodeModalContext
} from './hooks/useSendCodeModalContext'
import { isBrowser } from '../../../atoms/root'
import { selectDeviceSizeIsMobile } from '../../../state/reducers/deviceSlice'
import { useCodeSubmit } from './hooks/useCodeSubmit'
import { useSelector } from 'react-redux'

export const SendCodeModal = () => {
  const { codeProps, modal, ...context } = useSendCodeModalContext()
  const isMobile = useSelector(selectDeviceSizeIsMobile)
  const onCodeSubmit = useCodeSubmit()

  useEffect(() => {
    if (isBrowser) {
      GAEvent.visitRegistration('popup')
    }
  }, [])

  const modalProps = useMemo(() => {
    const stepWithBackButtonProps = {
      backButtonText: !isMobile ? 'Назад' : undefined,
      BackButtonIcon: isMobile ? <Icons.IconArrowLeft /> : undefined,
      withBackButtonHeader: true
    }
    return {
      ...stepWithBackButtonProps,
      ...(context.formData.loading
        ? {
            modalHeight: 425,
            loading: true
          }
        : {}),
      onBackButtonClick: codeProps.onBack
    }
  }, [context.formData, isMobile])

  return (
    <AuthModal hide={modal.hide} {...modalProps}>
      <Code onCodeSubmit={onCodeSubmit} />
    </AuthModal>
  )
}

export const SendCodeModalWithContext = ({ children }) => (
  <SendCodeModalModalContextProvider>
    {children}
  </SendCodeModalModalContextProvider>
)
