import React from 'react'
import styled from 'styled-components'
import { ControlMiniCheckboxes } from '../../molecules/FormItem'
import { documents } from '../../../static/db.json'
import { notEmpty } from '../../atoms/FormItems/validators/notEmpty'
import { size } from '../../constants'

const agreeListCheckboxes = [
  {
    name: 'personal_data',
    label: (
      <>
        {`Я согласен с `}
        <a href={documents.urlPolicy} rel="noreferrer" target="_blank">
          политикой обработки персональных данных
        </a>
        {' и принимаю '}
        <a href={documents.urlAgreement} rel="noreferrer" target="_blank">
          условия пользовательского соглашения
        </a>
      </>
    ),
    requiredField: true
  }
]

export const AgreeList = styled(
  ({ className, fields = ['conditions', 'personal_data'] }) => (
    <ControlMiniCheckboxes
      className={className}
      fields={fields}
      options={agreeListCheckboxes}
      validate={notEmpty}
    />
  )
)`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: ${size.sm}) {
    gap: 16px;
  }
`
